import { api } from '@/services/api'
import { put } from 'redux-saga/effects'
import { loadChatsBySearchAction } from '../../handles/loadChatsBySearchAction'
// import { setHasMoreChatsAction } from '../../actions'

export function* loadChatsBySearchSaga(action: ReturnType<typeof loadChatsBySearchAction.request>) {
  const { status, ownerId, operatorId, campaignIds, departmentId, search, isChatsWithUnreadedMessages } = action.payload
  try {
    const dto: any = { status, ownerId, operatorId, campaignIds, departmentId, search, isChatsWithUnreadedMessages }
    const response = yield api.post('/get-chats-by-search', dto)
    // yield put(
    //   setHasMoreChatsAction({
    //     state: false,
    //     status: isChatsWithUnreadedMessages ? ['chats_with_unread_messages'] : status
    //   })
    // )
    const searchResult = response?.data?.searchedChats?.length ? response.data.searchedChats : ['']
    yield put(
      loadChatsBySearchAction.success({
        chats: searchResult,
        status,
        isChatsWithUnreadedMessages
      })
    )
  } catch (error) {
    // yield put(setHasMoreChatsAction({ state: false, status }))
    yield put(loadChatsBySearchAction.failure(error))
  }
}
