import { ActionType, createAsyncAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { addChatInChatsState } from '../helpers'
import { Failure, Request, Success } from './types'

export const closeChatAction = createAsyncAction(
  '@chat/CLOSE_CHAT_REQUEST',
  '@chat/CLOSE_CHAT_SUCCESS',
  '@chat/CLOSE_CHAT_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, action: ActionType<typeof closeChatAction.request>): ChatState => ({
  ...state
})

const success = (state: ChatState, action: ActionType<typeof closeChatAction.success>): ChatState => ({
  ...state,
  inProgressChats: state.inProgressChats.filter(chat => chat.id !== action.payload?.chat?.id),
  finishedChats: addChatInChatsState(state.finishedChats, action.payload.chat as any),
  activeChat: { ...state.activeChat, ...action.payload.chat }
})

const failure = (state: ChatState, action: ActionType<typeof closeChatAction.failure>): ChatState => ({
  ...state
})

export const closeChatHandle = { asyncAction: closeChatAction, success, request, failure }
