import { ActionType, createAsyncAction } from 'typesafe-actions'
import { Failure, Request, Success } from './types'
import { ChatState } from '../../types'
import { getChatsWithUnreadMessages } from './helpers'

export const loadChatsBySearchAction = createAsyncAction(
  '@chat/LOAD_CHATS_BY_SEARCH_REQUEST',
  '@chat/LOAD_CHATS_BY_SEARCH_SUCCESS',
  '@chat/LOAD_CHATS_BY_SEARCH_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, { payload }: ActionType<typeof loadChatsBySearchAction.request>): ChatState => {
  const [firstStatus] = payload.status
  switch (firstStatus) {
    case 'open':
      return {
        ...state,
        isLoadingOpenChats: false,
        isSuccessInProgressChatsSearchApi: false,
        isErrorOnLoadingOpenChats: false
      }
    case 'in_progress':
      return {
        ...state,
        isLoadingInProgressChatsSearchApi: false,
        isSuccessInProgressChatsSearchApi: false,
        isErrorOnLoadingInProgressChats: false
      }
    default:
      return {
        ...state,
        isLoadingFinishedChats: false,
        isErrorOnLoadingFinishedChats: false,
        isSuccessInProgressChatsSearchApi: false
      }
  }
}

const success = (state: ChatState, { payload }: ActionType<typeof loadChatsBySearchAction.success>): ChatState => {
  const [firstStatus] = payload.status
  const statusWithUnreadMessages = payload?.isChatsWithUnreadedMessages ? 'chats_with_unread_messages' : null
  switch (firstStatus) {
    case 'open':
      return {
        ...state,
        isLoadingOpenChats: false,
        isErrorOnLoadingOpenChats: false,
        openChatsSearchApi: payload.chats,
        chatsWithUnreadMessagesSearchApi: getChatsWithUnreadMessages(payload.chats, statusWithUnreadMessages)
      }
    case 'in_progress':
      return {
        ...state,
        isLoadingInProgressChats: false,
        isSuccessInProgressChatsSearchApi: true,
        isErrorOnLoadingInProgressChats: false,
        inProgressChatsSearchApi: payload.chats,
        chatsWithUnreadMessagesSearchApi: getChatsWithUnreadMessages(payload.chats, statusWithUnreadMessages)
      }
    default:
      return {
        ...state,
        isLoadingFinishedChats: false,
        isErrorOnLoadingFinishedChats: false,
        finishedChatsSearchApi: payload.chats
      }
  }
}

const failure = (state: ChatState, action: ActionType<typeof loadChatsBySearchAction.failure>): ChatState => {
  switch (action.payload.status) {
    case 'open':
      return {
        ...state,
        isLoadingOpenChats: false,
        isErrorOnLoadingOpenChats: false
      }
    case 'in_progress':
      return {
        ...state,
        isLoadingInProgressChats: false,
        isErrorOnLoadingInProgressChats: false
      }
    default:
      return {
        ...state,
        isLoadingFinishedChats: false,
        isErrorOnLoadingFinishedChats: false
      }
  }
}

export const loadChatsBySearchHandle = { asyncAction: loadChatsBySearchAction, request, success, failure }
