import { createAsyncAction } from 'typesafe-actions'
import { MessageState } from '../../types'
import { Failure, Request, Success } from './types'

export const reSendMessageAction = createAsyncAction(
  '@message/RE_SEND_MESSAGE_REQUEST',
  '@message/RE_SEND_MESSAGE_SUCCESS',
  '@message/RE_SEND_MESSAGE_FAILURE'
)<Request, Success, Failure>()

const request = (state: MessageState): MessageState => ({
  ...state,
  isLoadingOnReSendMessage: true
})

const success = (state: MessageState): MessageState => {
  return {
    ...state,
    isSuccessOnReSendMessage: true,
    isLoadingOnReSendMessage: false
  }
}

const failure = (state: MessageState): MessageState => {
  return {
    ...state,
    isLoadingOnReSendMessage: false,
    isErrorOnReSendMessage: true
  }
}

export const reSendMessageHandle = {
  asyncAction: reSendMessageAction,
  request,
  success,
  failure
}
