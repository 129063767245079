import { isEmpty } from '@/helpers/string.helpers'
import { MessageModel } from '@/models/message.model'
import { Chat } from '../types'

export function addUniqueMessages(existingMessages: MessageModel[], newMessages: MessageModel[]) {
  if (!existingMessages?.length) {
    existingMessages = []
  }
  const updatedMessages = [...existingMessages, ...newMessages]
  return updatedMessages
}

export function updateChatInChatsState(currentChat: Chat, chats: Chat[]) {
  return chats.map(chat => (chat.id === currentChat.id ? currentChat : chat))
}

export function addChatInChatsState(existingChats: Chat[], chatToAdd: Chat): Chat[] {
  if (isEmpty(chatToAdd)) {
    return existingChats
  }
  const updatedChats = existingChats.filter(chat => chat.id !== chatToAdd.id)
  return [chatToAdd, ...updatedChats]
}
