import { ActionType, createAsyncAction } from 'typesafe-actions'
import { ChatState } from '../../types'
import { getChatsWithUnreadMessages } from './helpers'
import { Failure, Request, Success } from './types'

export const loadChatsByStatusAction = createAsyncAction(
  '@chat/LOAD_CHATS_BY_STATUS_REQUEST',
  '@chat/LOAD_CHATS_BY_STATUS_SUCCESS',
  '@chat/LOAD_CHATS_BY_STATUS_FAILURE'
)<Request, Success, Failure>()

const request = (state: ChatState, { payload }: ActionType<typeof loadChatsByStatusAction.request>): ChatState => {
  const [firstStatus] = payload.status
  switch (firstStatus) {
    case 'open':
      return {
        ...state,
        isLoadingOpenChats: true,
        isErrorOnLoadingOpenChats: false
      }
    case 'in_progress':
      return {
        ...state,
        isLoadingInProgressChats: true,
        isErrorOnLoadingInProgressChats: false
      }
    default:
      return {
        ...state,
        isLoadingFinishedChats: true,
        isErrorOnLoadingFinishedChats: false
      }
  }
}

const success = (state: ChatState, { payload }: ActionType<typeof loadChatsByStatusAction.success>): ChatState => {
  const [firstStatus] = payload.status
  const statusWithUnreadMessages = payload?.isChatsWithUnreadedMessages ? 'chats_with_unread_messages' : null
  switch (firstStatus) {
    case 'open':
      return {
        ...state,
        isLoadingOpenChats: false,
        isErrorOnLoadingOpenChats: false,
        openChats: statusWithUnreadMessages ? state.openChats : payload.chats,
        openChatsWithUnreadMessages: getChatsWithUnreadMessages(payload.chats, statusWithUnreadMessages)
      }
    case 'in_progress':
      return {
        ...state,
        isLoadingInProgressChats: false,
        isErrorOnLoadingInProgressChats: false,
        inProgressChats: statusWithUnreadMessages ? state.inProgressChats : payload.chats,
        inProgressChatsWithUnreadMessages: getChatsWithUnreadMessages(payload.chats, statusWithUnreadMessages)
      }
    default:
      return {
        ...state,
        isLoadingFinishedChats: false,
        isErrorOnLoadingFinishedChats: false,
        finishedChats: payload.chats
      }
  }
}

const failure = (state: ChatState, action: ActionType<typeof loadChatsByStatusAction.failure>): ChatState => {
  switch (action.payload.status) {
    case 'open':
      return {
        ...state,
        isLoadingOpenChats: false,
        isErrorOnLoadingOpenChats: true
      }
    case 'in_progress':
      return {
        ...state,
        isLoadingInProgressChats: false,
        isErrorOnLoadingInProgressChats: true
      }
    default:
      return {
        ...state,
        isLoadingFinishedChats: false,
        isErrorOnLoadingFinishedChats: true
      }
  }
}

export const loadChatsByStatusHandle = { asyncAction: loadChatsByStatusAction, request, success, failure }
