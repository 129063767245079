import { _fireBaseStorage, _fireStore } from '@/helpers/firebase'
import { collection, doc, getDocs, query, setDoc, where } from 'firebase/firestore'
import { call, put } from 'redux-saga/effects'
import { createNewChatAction, setActiveChatAction } from '../../handles'
import { isEmpty } from '@/helpers/string.helpers'
import { CreateMessageRequestParam } from '@/redux/message/actions'
import {
  SendMessageOfficialAPIResponse,
  SendMessageResponse
} from '@/redux/message/sagas/createMessageSaga/http/message/types'
import { sendMessage, sendMessageOfficialAPI } from '@/redux/message/sagas/createMessageSaga/http/message'
import { v4 as uuid } from 'uuid'
import { Chat } from '../../types'
import { getFullDate } from '@/helpers/date.helpers'
import { toast } from '@/helpers/toast/toast.helper'
import { SelectedDepartmentModel } from '@/models/department.model'
import { generateSixDigitTicketNumber } from '@/redux/message/utils'
import { MessageModel } from '@/models/message.model'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { ChatModel } from '@/models/chat.model'

export function* createNewChatSaga(action: ReturnType<typeof createNewChatAction.request>) {
  const { message, wabaId, ownerId, campaignId, token, callBack, department } = action.payload
  try {
    const newMessage = yield buildNewMessage(message)
    const chat: ChatModel = yield call(
      getChatByNumber,
      newMessage.senderNumber.toString(),
      newMessage.operator.id,
      ownerId,
      campaignId,
      ['open', 'in_progress']
    )

    if (isEmpty(chat)) {
      if (newMessage?.isOfficial) {
        const sendMessageOfficialAPIResponse: SendMessageOfficialAPIResponse = yield sendMessageOfficialAPI({
          campaignId,
          wabaId,
          body: {
            telefone: newMessage.phone,
            message: newMessage.messageText,
            templateMessageId: newMessage?.templateMessageId
          },
          token
        })
        if (!sendMessageOfficialAPIResponse?.messageId) {
          const errorMessage = 'Erro ao criar chat oficial, tente novamente'
          toast({ type: 'error', message: errorMessage })
          return yield put(createNewChatAction.failure(new Error(errorMessage)))
        }
        newMessage.messageId = sendMessageOfficialAPIResponse.messageId
        newMessage.status = sendMessageOfficialAPIResponse.status
        newMessage.messageText = newMessage?.messageText || sendMessageOfficialAPIResponse?.text?.message
      } else {
        const sendMessageResult: SendMessageResponse = yield call(
          sendMessageToZApiMultiService,
          newMessage,
          campaignId,
          token
        )
        newMessage.messageId = sendMessageResult.messageId
      }

      if (newMessage.messageId) newMessage.isSend = true
      const newChat = yield call(buildNewChat, { message: newMessage, ownerId, department })
      newMessage.chatId = newChat.id

      yield call(createNewMessageOnFirestore, newMessage)
      yield call(createNewChat, newChat)
      yield put(setActiveChatAction({ ...newChat, unreadMessagesCount: 0 }))
      yield put(createNewChatAction.success())
      callBack()
    } else {
      const errorMessage = `Atendimento já em aberto para o número informado no departamento ${chat.department.name}`
      toast({
        type: 'error',
        message: errorMessage
      })
      yield put(createNewChatAction.failure(new Error(errorMessage)))
    }
  } catch (error) {
    toast({ type: 'error', message: 'Erro ao criar chat, tente novamente' })
    console.log({ createNewChatSaga: error })
    yield put(createNewChatAction.failure(error))
  }
}

async function buildNewMessage(message: CreateMessageRequestParam['message']) {
  let file: MessageModel['file'] = {} as MessageModel['file']
  let filePath: string
  if (message.file) {
    filePath = `storage_to_${message.ownerId}/data/media/images`
    const fileUrl = await uploadFileToStorage(message.file, filePath)

    file = {
      name: message.file.name,
      type: message.file.type,
      size: message.file.size,
      url: fileUrl,
      typeFile: message.typeFile
    }
  }
  return {
    ...message,
    messageId: '',
    isRead: false,
    isSend: false,
    fromMe: true,
    createdAt: new Date().getTime(),
    file
  }
}

async function uploadFileToStorage(file: File, filePath: string) {
  const fileExt = file.name.split('.')[1]
  const buildedFileName = `${uuid()}.${fileExt}`
  const fileName = filePath + buildedFileName
  const storageRef = ref(_fireBaseStorage, fileName)
  try {
    const snapshot = await uploadBytes(storageRef, file)
    const downloadURL = await getDownloadURL(snapshot.ref)
    return downloadURL
  } catch (error) {
    throw new Error(error)
  }
}

async function sendMessageToZApiMultiService(newMessage: MessageModel, campaignId: string, token: string) {
  if (newMessage.messageText.trim()) {
    return await sendMessage({
      campaignId,
      token,
      body: {
        phone: newMessage.phone,
        message: newMessage.messageText
      }
    })
  }

  const [type] = newMessage.file.type.split('/')
  switch (type) {
    case 'image':
      if (newMessage?.file?.typeFile === 'sticker') {
        return await sendMessage({
          campaignId,
          token,
          documentType: 'send-sticker',
          body: {
            phone: newMessage.phone,
            sticker: newMessage.file.url
          },
          extensionFile: 'image/webp'
        })
      } else {
        return await sendMessage({
          campaignId,
          token,
          documentType: 'send-image',
          body: {
            phone: newMessage.phone,
            image: newMessage.file.url
          }
        })
      }
    case 'audio':
      return await sendMessage({
        campaignId,
        token,
        documentType: 'send-audio',
        body: {
          phone: newMessage.phone,
          audio: newMessage.file.url
        }
      })

    case 'video':
      return await sendMessage({
        campaignId,
        token,
        documentType: 'send-video',
        body: {
          phone: newMessage.phone,
          video: newMessage.file.url
        }
      })

    default:
      return await sendMessage({
        campaignId,
        token,
        documentType: 'send-document',
        extensionFile: newMessage.file.type.split('/')[1],
        body: {
          phone: newMessage.phone,
          document: newMessage.file.url
        }
      })
  }
}

type BuildNewChat = {
  message: MessageModel
  ownerId: string
  department: SelectedDepartmentModel
}

async function buildNewChat({ message, ownerId, department }: BuildNewChat): Promise<Partial<Chat>> {
  console.log({ chatMessage: message })
  const ticket = generateSixDigitTicketNumber()
  const baseDate = new Date()
  const dateNow = baseDate.getTime()
  const dateMonth = baseDate.getMonth()
  return {
    id: uuid(),
    department: { id: department?.id, name: department?.name, color: department?.color },
    departmentId: department?.id,
    ownerId,
    campaignId: message.campanhaId,
    status: 'in_progress',
    unreadMessagesCount: 0,
    receiverNumber: message.receiverNumber.toString(),
    requesterNumber: message.senderNumber.toString(),
    chatName: message.senderName || message.senderNumber.toString(),
    operatorsId: [message.operator.id],
    operators: [message.operator],
    startDate: dateNow,
    endDate: null,
    month: dateMonth,
    year: baseDate.getFullYear(),
    fullDate: getFullDate(baseDate),
    isFinishedUra: false,
    duration: 0,
    ticket,
    lastMessage: message as any,
    updatedAt: dateNow,
    createdAt: dateNow
  }
}

async function createNewChat(chat: Chat) {
  console.log({ chat })
  const _collectionName = `chats`
  const newChatDoc = doc(collection(_fireStore, _collectionName), chat.id)
  await setDoc(newChatDoc, chat)
}

async function createNewMessageOnFirestore(message: MessageModel) {
  const collectionName = `messages`
  const newMessageDoc = doc(collection(_fireStore, collectionName), message.messageId)
  await setDoc(newMessageDoc, {
    ...message,
    receiverNumber: message.senderNumber.toString(),
    senderNumber: message.receiverNumber.toString()
  })
}

async function getChatByNumber(
  senderNumber: string,
  operatorId: string,
  ownerId: string,
  campaignId: string,
  status: string[]
) {
  const _collectionName = `chats`
  const _query = query(
    collection(_fireStore, _collectionName),
    where('ownerId', '==', ownerId),
    where('operatorsId', 'array-contains', operatorId),
    where('campaignId', '==', campaignId),
    where('requesterNumber', '==', senderNumber),
    where('status', 'in', status)
  )
  const chatDocs = await getDocs(_query)
  if (chatDocs.empty) return null
  return chatDocs.docs.map(doc => doc.data())[0]
}
