import { MessageTemplateModel } from '@/models/message.model'
import { devzappApi } from '@/services/api'
import { put } from 'redux-saga/effects'
import { fetchMessageTemplatesAction } from '../../actions'

export function* fetchMessageTemplatesSaga(action: ReturnType<typeof fetchMessageTemplatesAction.request>) {
  try {
    const { campaignId } = action.payload
    const response = yield devzappApi.get<MessageTemplateModel[]>(
      `/api-meta-devzapp/message-template/campaign-aprove/${campaignId}`
    )

    yield put(fetchMessageTemplatesAction.success({ messageTemplates: response.data }))
  } catch (error) {
    yield put(fetchMessageTemplatesAction.failure(error))
  }
}
