import { call, cancel, delay, fork, put, take } from 'redux-saga/effects'
import { stopWatchChatsCountAction, watchChatsCountAction } from '../../handles'
import { fetchChatsCount } from './http/fetchChatsCount'
import { ChatsCountModel } from '@/models/chat.model'
import { FetchChatsCountSagaParams } from './types'

function* fetchChatsCountSaga({ departmentId, campaignIds, ownerId, operatorId }: FetchChatsCountSagaParams) {
  while (true) {
    try {
      if (!campaignIds.length) throw new Error('No campaign ids')
      if (!departmentId) throw new Error('No department id')
      const chatsCount: ChatsCountModel = yield call(fetchChatsCount, {
        departmentId,
        campaignIds,
        ownerId,
        operatorId
      })
      yield put(watchChatsCountAction.success(chatsCount))
      yield delay(30000)
    } catch (error) {
      yield put(watchChatsCountAction.failure(error))
      break
    }
  }
}

export function* watchChatsCountSaga(action: ReturnType<typeof watchChatsCountAction.request>) {
  const { departmentId, campaignIds, ownerId, operatorId } = action.payload

  const task = yield fork(fetchChatsCountSaga, { departmentId, campaignIds, ownerId, operatorId })

  yield take(stopWatchChatsCountAction().type)
  yield cancel(task)
}
