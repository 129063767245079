import { all, takeEvery, takeLatest } from 'redux-saga/effects'
import { createNewChatAction, transferChatToOperatorAction, updateChatFirebaseAction } from './chat/actions'
import { selectDepartmentAction } from './department/handles'
import { setDepartmentSaga } from './department/sagas/setDepartmentSaga'
import {
  archiveChatAction,
  changeChatStatusAction,
  deleteChatAction,
  fetchMoreChatsAction,
  loadChatsByStatusAction,
  loadMessagesByChatIdAction,
  readMessagesFromChatAction,
  watchChatsCountAction
} from './chat/handles'
import { cancelChatAction } from './chat/handles/cancelChatAction'
import { closeChatAction } from './chat/handles/closeChatAction'
import { finalCloseChatAction } from './chat/handles/finalCloseChatAction'
import { loadChatsBySearchAction } from './chat/handles/loadChatsBySearchAction'
import { archiveChatSaga, deleteChatByIdSaga, loadMessagesByChatIdSaga, readMessagesFromChatSaga } from './chat/sagas'
import { cancelChatSaga } from './chat/sagas/cancelChatSaga'
import { changeChatStatusSaga } from './chat/sagas/changeChatStatusSaga'
import { closeChatSaga } from './chat/sagas/closeChatSaga'
import { createNewChatSaga } from './chat/sagas/createNewChatSaga'
import { fetchMoreChatsSaga } from './chat/sagas/fetchMoreChatsSaga'
import { finalCloseChatSaga } from './chat/sagas/finalCloseChatSaga'
import { loadChatsBySearchSaga } from './chat/sagas/loadChatsBySearchSaga'
import { loadChatsByStatusSaga } from './chat/sagas/loadChatsByStatusSaga'
import { updateChatFirebaseSaga } from './chat/sagas/updateChatFirebaseSaga'
import { loadCompanyPhonesAction, setSelectedCompanyAction } from './company/handles'
import { loadCompanyPhonesSaga } from './company/sagas/loadCompanyPhonesSaga'
import { setSelectedCompanySaga } from './company/sagas/setSelectedCompanySaga'
import { createContactAction } from './contact/handles'
import { createContactSaga } from './contact/sagas'
import {
  createMessageAsync,
  deleteMessageAsync,
  fetchMessageTemplatesAction,
  fetchMoreMessagesAsync,
  reSendMessageAction
} from './message/actions'
import { createMessageSaga, deleteMessageSaga, fetchMoreMessagesSaga } from './message/sagas'
import { saveNewStickerAction } from './sticker/actions'
import { deleteStickerAction, getStickersAction } from './sticker/handles'
import { deleteStickerSaga } from './sticker/sagas/deleteStickerSaga'
import { getStickersSaga } from './sticker/sagas/getStickersSaga'
import { saveNewStickerSaga } from './sticker/sagas/saveNewStickerSaga'
import { signInAsync, signOutAsync, updateUserAction } from './user/actions'
import { signInSaga } from './user/sagas/signInSaga'
import { signOutSaga } from './user/sagas/signOutSaga'
import { updateUserSaga } from './user/sagas/updateUser'
import { transferChatAction } from './chat/handles/transferChatAction'
import { transferChatSaga } from './chat/sagas/transferChatSaga'
import { transferChatToOperatorSaga } from './chat/sagas/transferChatToOperatorSaga'
import { customButtonCallAction } from './chat/handles/customButtonCallAction'
import { customButtonCallSaga } from './chat/sagas/customButtonCallSaga'
import { watchChatsCountSaga } from './chat/sagas/watchChatsCountSaga'
import { fetchMessageTemplatesSaga } from './message/sagas/fetchMessageTemplatesSaga'
import { reSendMessageSaga } from './message/sagas/reSendMessageSaga'

export default function* rootSaga() {
  return yield all([
    takeLatest(setSelectedCompanyAction.request, setSelectedCompanySaga),
    takeEvery(createContactAction.request, createContactSaga),
    takeLatest(signOutAsync.request, signOutSaga),
    takeEvery(signInAsync.request, signInSaga),
    takeEvery(updateUserAction.request, updateUserSaga),
    takeEvery(loadChatsByStatusAction.request, loadChatsByStatusSaga),
    takeEvery(loadChatsBySearchAction.request, loadChatsBySearchSaga),
    takeLatest(loadCompanyPhonesAction.request, loadCompanyPhonesSaga),
    takeLatest(changeChatStatusAction.request, changeChatStatusSaga),
    takeEvery(fetchMoreChatsAction.request, fetchMoreChatsSaga),
    takeLatest(deleteChatAction.request, deleteChatByIdSaga),
    takeLatest(archiveChatAction.request, archiveChatSaga),
    takeLatest(loadMessagesByChatIdAction.request, loadMessagesByChatIdSaga),
    takeLatest(fetchMoreMessagesAsync.request, fetchMoreMessagesSaga),
    takeLatest(readMessagesFromChatAction.request, readMessagesFromChatSaga),
    takeEvery(createMessageAsync.request, createMessageSaga),
    takeLatest(createNewChatAction.request, createNewChatSaga),
    takeLatest(deleteMessageAsync.request, deleteMessageSaga),
    takeLatest(closeChatAction.request, closeChatSaga),
    takeLatest(finalCloseChatAction.request, finalCloseChatSaga),
    takeLatest(cancelChatAction.request, cancelChatSaga),
    takeLatest(updateChatFirebaseAction.request, updateChatFirebaseSaga),
    takeLatest(saveNewStickerAction.request, saveNewStickerSaga),
    takeLatest(getStickersAction.request, getStickersSaga),
    takeLatest(deleteStickerAction.request, deleteStickerSaga),
    takeLatest(transferChatAction.request, transferChatSaga),
    takeLatest(transferChatToOperatorAction.request, transferChatToOperatorSaga),
    takeLatest(selectDepartmentAction.request, setDepartmentSaga),
    takeLatest(customButtonCallAction.request, customButtonCallSaga),
    takeLatest(watchChatsCountAction.request, watchChatsCountSaga),
    takeLatest(fetchMessageTemplatesAction.request, fetchMessageTemplatesSaga),
    takeLatest(reSendMessageAction.request, reSendMessageSaga)
  ])
}
