import { ActionType, createAction } from 'typesafe-actions'
import { ChatState } from '../../types'

export const clearChatsWithUnreadMessagesAction = createAction('@chat/CLEAR_CHATS_WITH_UNREAD_MESSAGES')<void>()

const success = (state: ChatState, action: ActionType<typeof clearChatsWithUnreadMessagesAction>): ChatState => ({
  ...state,
  openChatsWithUnreadMessages: [],
  inProgressChatsWithUnreadMessages: []
})

export const clearChatsWithUnreadMessagesHandle = { asyncAction: clearChatsWithUnreadMessagesAction, success }
