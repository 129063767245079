import { createAction } from 'typesafe-actions'
import { Chat } from './types'
export {
  clearChatsWithUnreadMessagesAction,
  addArchiveChatAction,
  addChatAction,
  addMessageOnActiveChatAction,
  answerChatAction,
  archiveChatAction,
  changeChatStatusAction,
  deleteChatAction,
  deleteMessageFromChatAction,
  fetchMoreChatsAction,
  loadChatsByStatusAction,
  loadMessagesByChatIdAction,
  readMessagesFromChatAction,
  removeArchiveChatAction,
  setActiveChatAction,
  setActiveChatMessageAction,
  setActiveChatMessagesAction,
  updateActiveChatAction,
  updateArchiveChatAction,
  updateChatAction,
  clearActiveChatAction,
  setHasMoreChatsAction,
  cancelChatAction,
  createNewChatAction,
  closeChatAction,
  finalCloseChatAction,
  loadChatsBySearchAction,
  updateChatFirebaseAction,
  transferChatAction,
  transferChatToOperatorAction,
  customButtonCallAction,
  watchChatsCountAction,
  stopWatchChatsCountAction
} from './handles'

export const removeChat = createAction('@chat/REMOVE_CHAT')<Chat>()
