import { ActionType, createReducer } from 'typesafe-actions'
import { createMessageAsync, setHasMoreMessages, fetchMessageTemplatesHandle, reSendMessageHandle } from './actions'
import { MessageAction, MessageState } from './types'

const initialState: MessageState = {
  messages: [],
  isLoading: false,
  error: false,
  hasMoreMessages: false,
  isErrorOnCreateMessage: false,
  isLoadingCreateMessage: false,
  isSuccessCreateMessage: false,
  isLoadingAttachment: false,
  isLoadingAudio: false,
  isLoadingSticker: false,
  isLoadingSendMessageTemplate: false,
  messageTemplates: [],
  isErrorOnFetchMessageTemplates: false,
  isLoadingOnFetchMessageTemplates: false,
  isSuccessOnFetchMessageTemplates: false,
  isErrorOnReSendMessage: false,
  isLoadingOnReSendMessage: false,
  isSuccessOnReSendMessage: false
}

export const messageReducer = createReducer<MessageState, MessageAction>(initialState)
  .handleAction(
    createMessageAsync.request,
    (state: MessageState, action: ActionType<typeof createMessageAsync.request>): MessageState => ({
      ...state,
      isLoading: true,
      isLoadingCreateMessage: true,
      isLoadingAttachment: action.payload?.typeFile === 'attachment',
      isLoadingAudio: action.payload?.typeFile === 'audio',
      isLoadingSticker: action.payload?.typeFile === 'sticker',
      isLoadingSendMessageTemplate: action.payload?.typeFile === 'messageTemplate'
    })
  )
  .handleAction(
    createMessageAsync.success,
    (state: MessageState): MessageState => ({
      ...state,
      isLoading: false,
      isSuccessCreateMessage: true,
      isLoadingAttachment: false,
      isLoadingAudio: false,
      isLoadingSendMessageTemplate: false,
      isLoadingSticker: false
    })
  )
  .handleAction(
    createMessageAsync.failure,
    (state: MessageState, { payload }: ActionType<typeof createMessageAsync.failure>): MessageState => {
      console.log({ payload })
      return {
        ...state,
        isLoading: false,
        error: true,
        isErrorOnCreateMessage: true,
        isLoadingCreateMessage: false,
        isLoadingAttachment: false,
        isLoadingAudio: false,
        isLoadingSticker: false,
        isLoadingSendMessageTemplate: false
      }
    }
  )
  .handleAction(
    setHasMoreMessages,
    (state: MessageState, action: ActionType<typeof setHasMoreMessages>): MessageState => ({
      ...state,
      isLoading: false,
      error: null,
      hasMoreMessages: action.payload.state
    })
  )
  .handleAction(fetchMessageTemplatesHandle.asyncAction.request, fetchMessageTemplatesHandle.request)
  .handleAction(fetchMessageTemplatesHandle.asyncAction.success, fetchMessageTemplatesHandle.success)
  .handleAction(fetchMessageTemplatesHandle.asyncAction.failure, fetchMessageTemplatesHandle.failure)
  .handleAction(reSendMessageHandle.asyncAction.request, reSendMessageHandle.request)
  .handleAction(reSendMessageHandle.asyncAction.success, reSendMessageHandle.success)
  .handleAction(reSendMessageHandle.asyncAction.failure, reSendMessageHandle.failure)
